import calculateWinner from "./calculateWinner";

function Square({ value, onSquareClick, highlight }) {
  let color;
  if (value === "X") {
    color = "red";
  } else if (value === "O") {
    color = "blue";
  } else {
    color = "";
  }

  return (
    <button
      className={`square ${color} ${highlight ? "highlight" : ""}`}
      onClick={onSquareClick}
    >
      {value}
    </button>
  );
}

export default function Board({ xIsNext, squares, onPlay }) {
  function handleClick(i) {
    if (squares[i] || calculateWinner(squares)) {
      return;
    }
    const nextSquares = squares.slice();
    if (xIsNext) {
      nextSquares[i] = "X";
    } else {
      nextSquares[i] = "O";
    }
    onPlay(nextSquares);
  }

  const winner = calculateWinner(squares);
  let status;

  if (winner) {
    status = "Winner: " + winner.win;
  } else {
    status = "Next player: " + (xIsNext ? "X" : "O");
  }

  return (
    <>
      <div className="status">{status}</div>
      <div className="board-row">
        <Square
          value={squares[0]}
          onSquareClick={() => handleClick(0)}
          highlight={winner ? winner.lines?.includes(0) : ""}
        />
        <Square
          value={squares[1]}
          onSquareClick={() => handleClick(1)}
          highlight={winner ? winner.lines?.includes(1) : ""}
        />
        <Square
          value={squares[2]}
          onSquareClick={() => handleClick(2)}
          highlight={winner ? winner.lines?.includes(2) : ""}
        />
      </div>
      <div className="board-row">
        <Square
          value={squares[3]}
          onSquareClick={() => handleClick(3)}
          highlight={winner ? winner.lines?.includes(3) : ""}
        />
        <Square
          value={squares[4]}
          onSquareClick={() => handleClick(4)}
          highlight={winner ? winner.lines?.includes(4) : ""}
        />
        <Square
          value={squares[5]}
          onSquareClick={() => handleClick(5)}
          highlight={winner ? winner.lines?.includes(5) : ""}
        />
      </div>
      <div className="board-row">
        <Square
          value={squares[6]}
          onSquareClick={() => handleClick(6)}
          highlight={winner ? winner.lines?.includes(6) : ""}
        />
        <Square
          value={squares[7]}
          onSquareClick={() => handleClick(7)}
          highlight={winner ? winner.lines?.includes(7) : ""}
        />
        <Square
          value={squares[8]}
          onSquareClick={() => handleClick(8)}
          highlight={winner ? winner.lines?.includes(8) : ""}
        />
      </div>
    </>
  );
}
