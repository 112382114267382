export default function Statictics({ stats }) {
  return (
    <>
      <h2>Statistics</h2>
      <table>
        <tbody>
          <tr>
            <td>X wins:</td>
            <td>{stats.X}</td>
          </tr>
          <tr>
            <td>O wins:</td>
            <td>{stats.O}</td>
          </tr>
          <tr>
            <td>Totally:</td>
            <td>{stats.Total}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
