import { useState, useEffect } from "react";

export default function ThemeToggle() {
  // light default
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light"; // get from local storage
  });

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme); // save to local storage
  }, [theme]);

  function toggleTheme() {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  }

  return (
    <button className="history" onClick={toggleTheme}>
      {theme === "light" ? "☼" : "☽"}
    </button>
  );
}
