// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .matrix-rain {
    --font-size-matrix-rain: 10px;
    display: none !important;
  }
  .square {
    --s: 80px;
    font-size: 36px;
  }
  .game {
    flex-direction: column;
  }

  .history-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .panel {
    margin-top: 0;
  }
  .history.restart {
    margin-left: 39%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;IAC7B,wBAAwB;EAC1B;EACA;IACE,SAAS;IACT,eAAe;EACjB;EACA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,2BAA2B;IAC3B,eAAe;EACjB;EACA;IACE,aAAa;EACf;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@media screen and (max-width: 600px) {\n  .matrix-rain {\n    --font-size-matrix-rain: 10px;\n    display: none !important;\n  }\n  .square {\n    --s: 80px;\n    font-size: 36px;\n  }\n  .game {\n    flex-direction: column;\n  }\n\n  .history-container {\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n  }\n  .panel {\n    margin-top: 0;\n  }\n  .history.restart {\n    margin-left: 39%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
